/* You can add global styles to this file, and also import other style files */
* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-y: hidden;
}
#page {
  width:100vw;
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}
#spinner-container {
  flex-grow:1;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
}
#spinner {
  user-select: none;
  pointer-events:none;
  max-width:50%;
}
